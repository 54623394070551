import React from 'react'
import { useTranslation } from 'react-i18next'

const ConnectivityStatus = ({ online }) => {
  const { t } = useTranslation('common')
  return <div className={`status ${online ? 'status--green' : 'status--red'}`}>
    <div className='status__center' />
    <span className='status__message'>{online ? t('online') : t('offline')}</span>
  </div>
}

export default ConnectivityStatus
