import fetchFromAPI from './fetchFromAPI'

export const activateAccount = (token) => fetchFromAPI(
  'app/activateAccount',
  {
    method: 'POST',
    body: JSON.stringify({ token })
  }
)

export const getConnectivityStatus = () => fetchFromAPI('app/api/connectivity')

export const checkPlayerStatus = ({ name, surname, birthDate, birthPlace }) => fetchFromAPI(
  'app/api/spielerstatus',
  {
    method: 'POST',
    body: JSON.stringify({
      name,
      surname,
      birthDate,
      birthPlace
    })
  }
)

export const getLandCodes = () => fetchFromAPI('app/api/landcodes')

export const getBlockReasons = () => fetchFromAPI('app/api/blockreasons')

export const blockPlayer = (body) => fetchFromAPI(
  'app/api/sperren',
  {
    method: 'POST',
    body: JSON.stringify(body)
  }
)

export const countScan = (body) => fetchFromAPI(
  'app/api/scan',
  {
    method: 'POST',
    body: JSON.stringify(body)
  }
)
