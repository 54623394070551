import React from 'react'

const SystemInformationIcon = () =>
  <svg
    width='75'
    height='46'
    viewBox='0 0 75 46'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
  >
    <title>System information</title>
    <path d='M41 13.8C41 13 41.7 12.3 42.5 12.3H73.5C74.3 12.3 75 13 75 13.8C75 14.6 74.3 15.3 73.5 15.3H42.5C41.7 15.3 41 14.7 41 13.8Z' />
    <path d='M41 20.8C41 20 41.7 19.3 42.5 19.3H73.5C74.3 19.3 75 20 75 20.8C75 21.6 74.3 22.3 73.5 22.3H42.5C41.7 22.3 41 21.7 41 20.8Z' />
    <path d='M41 27.8C41 27 41.7 26.3 42.5 26.3H73.5C74.3 26.3 75 27 75 27.8C75 28.6 74.3 29.3 73.5 29.3H42.5C41.7 29.3 41 28.7 41 27.8Z' />
    <path d='M41 34.8C41 34 41.7 33.3 42.5 33.3H73.5C74.3 33.3 75 34 75 34.8C75 35.6 74.3 36.3 73.5 36.3H42.5C41.7 36.3 41 35.7 41 34.8Z' />
    <path
      d='M27.8 9.3H9.2C8.9 9.3 8.6 9.4 8.4 9.6C8.1 9.9 8 10.2 8 10.5V29.2C8 29.5 8.1 29.8 8.3 30C8.5 30.2 8.8 30.3 9.1 30.3H27.8C28.1 30.3 28.4 30.2 28.6 30C28.8 29.8 28.9 29.5 28.9 29.2V10.5C28.9 10.2 28.8 9.9 28.6 9.7C28.4 9.5 28.1 9.3 27.8 9.3ZM11.6 28C11.9 26.4 12.7 24.9 14 23.8C15.3 22.7 16.9 22.1 18.5 22.1C20.1 22.1 21.8 22.7 23 23.8C24.3 24.9 25.1 26.3 25.4 28H11.6ZM16.2 17.5C16.2 17 16.3 16.6 16.6 16.2C16.9 15.8 17.2 15.5 17.6 15.3C18 15.1 18.5 15.1 18.9 15.2C19.4 15.3 19.8 15.5 20.1 15.8C20.4 16.1 20.6 16.5 20.7 17C20.8 17.5 20.7 17.9 20.6 18.3C20.4 18.7 20.1 19.1 19.7 19.3C19.3 19.6 18.9 19.7 18.4 19.7C17.8 19.7 17.2 19.5 16.8 19C16.4 18.7 16.2 18.1 16.2 17.5ZM26.7 24.7C25.7 22.8 24 21.4 22.1 20.6C22.7 19.9 23.1 19.1 23.2 18.2C23.3 17.3 23.2 16.4 22.8 15.6C22.4 14.8 21.8 14.1 21.1 13.6C20.3 13.1 19.5 12.9 18.6 12.9C17.7 12.9 16.8 13.2 16.1 13.6C15.3 14.1 14.7 14.8 14.4 15.6C14 16.4 13.9 17.3 14 18.2C14.1 19.1 14.5 19.9 15.1 20.6C13.1 21.4 11.5 22.9 10.5 24.7V11.7H26.8V24.7H26.7Z'
    />
    <path d='M0 3.3C0 1.6 1.3 0.300003 3 0.300003H67C68.7 0.300003 70 1.6 70 3.3V12.3H66.9V4.40001C66.9 3.80001 66.5 3.40001 65.9 3.40001H4.1C3.5 3.40001 3.1 3.80001 3.1 4.40001V41.3C3.1 41.9 3.5 42.3 4.1 42.3H65.9C66.5 42.3 66.9 41.9 66.9 41.3V36.4H70V42.4C70 44.1 68.7 45.4 67 45.4H3C1.3 45.4 0 44.1 0 42.4V3.3Z' />
  </svg>

export default SystemInformationIcon
