import React from 'react'
import { useProvideAuth } from '../../hooks/useProvideAuth'
import { authContext } from '../../hooks/useAuth'

export const ProviderAuth = ({ children }) => {
  const auth = useProvideAuth()
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  )
}
