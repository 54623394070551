import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import React, { useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { lockType, stateReducer } from '../consts'
import OasisSelect from '../OasisSelect'
import OasisTextField from '../OasisTextField'
import OasisButton from './../OasisButton'
import BlockFooter from './BlockFooter'

const locksMap = new Map([
  [lockType.self, 'playerBlock.selbstsperre'],
  [lockType.thirdParty, 'playerBlock.fremdsperre']
])

const Lock = ({ label, onResults }) => {
  const { t } = useTranslation('oasisApp')
  const [state, dispatch] = useReducer(stateReducer, {
    selectedLock: lockType.self
  })
  const selbstSelected = state.selectedLock === lockType.self
  const buttonDisabled = selbstSelected ? false : !(!selbstSelected && state.opinion)

  return <Grid
    container
    direction='column'
    alignItems='center'
    justifyContent='center'
    spacing={1}
  >
    <Grid
      item
    >
      <Typography
        variant='h6'
        color='primary'>
        {label}
      </Typography>
    </Grid>
    <Grid
      item
      container
      xs={10}
    >
      <OasisSelect
        label={t('playerBlock.lock')}
        id='lock'
        value={state.selectedLock}
        onChange={({ target: { value: selectedLock } }) => dispatch({
          selectedLock,
          lastName: '',
          firstName: '',
          opinion: ''
        })}
      >
        {[...locksMap].map(([key, value]) =>
          <MenuItem key={key} value={key}>
            <em>{t(value)}</em>
          </MenuItem>
        )}
      </OasisSelect>
      {!selbstSelected && <>
        <FormControl fullWidth>
          <OasisTextField
            label={t('playerBlock.applicantLastName')}
            value={state.lastName}
            onInput={({ target: { value: lastName } }) => dispatch({ lastName })}
            disabled={selbstSelected}
          />
        </FormControl>
        <FormControl fullWidth>
          <OasisTextField
            label={t('playerBlock.applicantFirstName')}
            value={state.firstName}
            onInput={({ target: { value: firstName } }) => dispatch({ firstName })}
            disabled={selbstSelected}
          />
        </FormControl>
        <FormControl fullWidth>
          <OasisTextField
            style={{ marginTop: '35px' }}
            label={t('playerBlock.applicantStatement')}
            value={state.opinion}
            onInput={({ target: { value: opinion } }) => dispatch({ opinion })}
            disabled={selbstSelected}
          />
        </FormControl>
      </>
      }
      <FormControl fullWidth>
        <OasisTextField
          InputProps={{
            readOnly: true
          }}
          multiline
          minRows={3}
          maxRows={10}
          fullWidth
          label={t('playerBlock.disclaimerLabel')}
          value={t('playerBlock.disclaimer')}
        />
      </FormControl>
    </Grid>
    <BlockFooter>
      <OasisButton
        className='form-button'
        disabled={buttonDisabled}
        onClick={() => onResults(state)}
      >
        {t('common.next')}
      </OasisButton>
    </BlockFooter>
  </Grid>
}

export default Lock
