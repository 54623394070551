import fetchFromAPI from './fetchFromAPI';

const dictionaryMetadata = async () => {
    try {
        return await fetchFromAPI('app/meta-data');
    } catch (error) {
        console.error('Failed to fetch dictionary metadata:', error);
        throw error;
    }
};

export default dictionaryMetadata;
