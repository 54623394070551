import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import { useNavigate, Link, matchPath, useLocation } from 'react-router-dom'
import useConnectivityStatus from '../hooks/useConnectivityStatus'
import logoImage from '../images/svg/new-logo.png'
import ConnectivityStatus from './ConnectivityStatus'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import UserMenu from './UserMenu'

const Header = () => {
  const { pathname } = useLocation()
  const showBackButton = matchPath("/app/*", pathname) && !matchPath("/app", pathname)

  const online = useConnectivityStatus()
  const navigate = useNavigate()

  return (
    <Grid
      container
      className='header'
      direction='row'
      alignItems='center'
    >
      <Grid
        xs
        item
      >
        {showBackButton &&
          <IconButton
            color='primary'
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon alt='Go Back' />
          </IconButton>
        }
      </Grid>
      <Grid
        xs
        container
        direction='row'
        alignItems='center'
        item
        className='header__logo'
      >
        <Link to='/app'>
          <img src={logoImage} alt='Go Back' className='header__logo__image' />
        </Link>
      </Grid>
      <Grid
        xs
        item
        className='header__right'
      >
        <ConnectivityStatus online={online} />
        <UserMenu />
      </Grid>
    </Grid>)
}

export default Header
