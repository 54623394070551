import React from 'react'

const PlayerStatusIcon = () =>
  <svg
    width='75'
    height='53'
    viewBox='0 0 75 53'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
  >
    <title>Block player</title>
    <path
      d='M28.7001 10C28.5001 9.80002 28.2001 9.70001 27.9001 9.70001H9.2001C8.9001 9.70001 8.6001 9.80002 8.4001 10C8.2001 10.2 8.1001 10.5 8.1001 10.8V29.5C8.1001 29.8 8.2001 30.1 8.4001 30.3C8.6001 30.5 8.9001 30.6 9.2001 30.6H27.9001C28.2001 30.6 28.5001 30.5 28.7001 30.3C28.9001 30.1 29.0001 29.8 29.0001 29.5V10.8C29.0001 10.5 28.9001 10.2 28.7001 10ZM11.6001 28.6C11.9001 27 12.7001 25.5 14.0001 24.4C15.3001 23.3 16.9001 22.7 18.5001 22.7C20.2001 22.7 21.8001 23.3 23.0001 24.4C24.3001 25.5 25.1001 26.9 25.4001 28.6H11.6001ZM16.2001 17.8C16.2001 17.3 16.3001 16.9 16.6001 16.5C16.9001 16.1 17.2001 15.8 17.6001 15.6C18.0001 15.4 18.5001 15.4 18.9001 15.5C19.4001 15.6 19.8001 15.8 20.1001 16.1C20.4001 16.4 20.6001 16.8 20.7001 17.3C20.8001 17.8 20.7001 18.2 20.6001 18.6C20.4001 19 20.1001 19.4 19.7001 19.6C19.3001 19.9 18.9001 20 18.4001 20C17.8001 20 17.2001 19.8 16.8001 19.3C16.4001 19 16.2001 18.5 16.2001 17.8ZM26.7001 25C25.7001 23.1 24.0001 21.7 22.1001 20.9C22.7001 20.2 23.1001 19.4 23.2001 18.5C23.3001 17.6 23.2001 16.7 22.8001 15.9C22.4001 15.1 21.8001 14.4 21.1001 13.9C20.3001 13.4 19.5001 13.2 18.6001 13.2C17.7001 13.2 16.8001 13.5 16.1001 13.9C15.3001 14.4 14.7001 15.1 14.4001 15.9C14.0001 16.7 13.9001 17.6 14.0001 18.5C14.1001 19.4 14.5001 20.2 15.1001 20.9C13.1001 21.7 11.5001 23.2 10.5001 25V12H26.8001V25H26.7001Z'
    />
    <path d='M70.9 25.7H70V3.70001C70 2.00001 68.7 0.700012 67 0.700012H3C1.3 0.700012 0 2.00001 0 3.70001V42.7C0 44.4 1.3 45.7 3 45.7H42V48.7C42 50.9 43.9 52.7 46.1 52.7H70.9C73.2 52.7 75 50.9 75 48.7V29.8C75 27.5 73.1 25.7 70.9 25.7ZM42 29.7V42.6H4.1C3.5 42.6 3.1 42.2 3.1 41.6V4.70001C3.1 4.10001 3.5 3.70001 4.1 3.70001H65.9C66.5 3.70001 66.9 4.10001 66.9 4.70001V12.6C64.9 10.2 62 8.60001 58.6 8.60001C52.7 8.60001 47.9 13.5 47.9 19.5V25.6H46C43.9 25.7 42 27.5 42 29.7ZM66.6 19.5V25.6H50.6V19.5C50.6 15 54.2 11.4 58.6 11.4C63.1 11.4 66.6 15 66.6 19.5ZM72.3 48.6C72.3 49.3 71.7 49.9 70.9 49.9H46.1C45.3 49.9 44.7 49.3 44.7 48.6V29.7C44.7 29 45.3 28.4 46.1 28.4H70.9C71.7 28.4 72.3 29 72.3 29.7V48.6V48.6Z' />
    <path d='M57.9 40.5C56.8 40.1 56 39 56 37.7C56 36 57.3 34.7 58.9 34.7C60.5 34.7 61.8 36 61.8 37.7C61.8 39 61 40.1 59.9 40.5V44.7H58V40.5H57.9Z' />
  </svg>

export default PlayerStatusIcon
