import * as React from 'react'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { green } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    width: '100%',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}))

// TODO move to theme
const OasisButton = ({ children, onClick, disabled, loading, ...buttonProps }) => {
  const classes = useStyles()

  return <div className={classes.wrapper}>
    <Button
      fullWidth
      variant='contained'
      color='primary'
      size='large'
      onClick={onClick}
      disabled={disabled || loading}
      {...buttonProps}
    >
      {children}
    </Button>
    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
  </div>
}

OasisButton.propTypes = {
  label: PropTypes.string
}
OasisButton.defaultProps = {
  label: ''
}

export default OasisButton
