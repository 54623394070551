import React from 'react'
import Grid from '@material-ui/core/Grid'
import LoginForm from './LoginForm'
import Logo from './Logo'

const LoginScreen = () =>
  <Grid
    className='login-screen'
    container
    direction='column'
    justifyContent='center'
    alignItems='center'
  >
    <Logo />
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <LoginForm />
    </Grid>
  </Grid>

export default LoginScreen
