import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Link, makeStyles, Typography } from '@material-ui/core'
import OasisButton from './OasisButton'
import { useTranslation } from 'react-i18next'
import { useNavigate, Navigate, useLocation } from 'react-router-dom'
import EmailIcon from '@material-ui/icons/Email'

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    marginBottom: theme.spacing(3),
    maxWidth: theme.spacing(50)
  },
  typographyHeader: {
    textAlign: 'center',
    fontSize: '48px',
    fontWeight: 'bold',
    lineHeight: '48px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4)
  },
  typographyBody: {
    textAlign: 'center',
  },
  link: {
    textAlign: 'center',
  },
  icon: {
    fontSize: '100px',
  }
}))

const NotActivatedScreen = () => {
  const { t } = useTranslation('oasisApp')
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()

  const email = location?.state?.email

  return <>
    {
      email
        ? <Grid
          className='login-screen'
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
        >
          <EmailIcon
            color='primary'
            className={classes.icon}
          />
          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
            className={classes.titleContainer}
          >
            <Typography
              color='primary'
              className={classes.typographyHeader}
            >
              {t('common.activateAccount')}
            </Typography>
            <Typography className={classes.typographyBody}>{t('common.activationLinkSent')}</Typography>
            <Link
              color='textPrimary'
              underline='always'
              className={classes.link}
            >
              {email}
            </Link>
            <Typography className={classes.typographyBody}>{t('common.checkYourEmail')}</Typography>
          </Grid>
          <Grid item>
            <OasisButton
              onClick={() => navigate('/')}
            >
              {t('common.backToLogin')}
            </OasisButton>
          </Grid>
        </Grid>
        : <Navigate to='/' replace />
    }
  </>
}

export default NotActivatedScreen
