import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.scss'
import ActivateAccountScreen from './components/ActivateAccountScreen'
import Layout from './components/Layout'
import LoginScreen from './components/LoginScreen'
import { PrivateRoute } from './components/PrivateRoute'
import MenuScreen from './components/menu/MenuScreen'
import PlayerStatus from './components/playerstatus/PlayerStatus'
import PlayerBlock from './components/playerblock/PlayerBlock'
import SystemInformation from './components/SystemInformation'
import ShopSelection from './components/ShopSelection'
import { ProviderAuth } from './components/providers/ProviderAuth'
import NoValidLicense from './components/NoValidLicense'
import ConnectivityProvider from './components/providers/ConnectivityProvider'
import logger from './logger'
import NotActivatedScreen from './components/NotActivatedScreen'
import LockedShop from './components/LockedShop'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'
import { MetadataProvider } from "./hooks/useMetadataContext";

const App = () => {
  logger.start()
  const { i18n } = useTranslation()

  return <Router>
    <ProviderAuth>
        <MetadataProvider>
          <Layout>
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
              scriptProps={{ async: true, defer: true}}
              language={i18n.language}
            >
              <Routes>
                <Route path='/' element={<LoginScreen />} />
                <Route path='/activateaccount' element={<ActivateAccountScreen />} />
                <Route path='/notactivated' element={<NotActivatedScreen />} />
                <Route path='/app/*' element={<PrivateRoute />}>
                  <Route path='shopselection' element={<ShopSelection />} />
                  <Route path='novalidlicense' element={<NoValidLicense />} />
                  <Route path='shoplocked' element={<LockedShop />} />
                  <Route path='' element={<ConnectivityProvider />}>
                    <Route path='' element={<MenuScreen />} />
                    <Route path='playerstatus/*' element={<PlayerStatus />} />
                    <Route path='playerblock/*' element={<PlayerBlock />} />
                    <Route path='systeminformation' element={<SystemInformation />} />
                  </Route>
                </Route>
              </Routes>
            </GoogleReCaptchaProvider>
          </Layout>
        </MetadataProvider>
    </ProviderAuth>
  </Router>
}

export default App
