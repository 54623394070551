import { init } from '@anyline/anyline-js'
import logger from '../logger'
import { countScan } from '../services/api'
import { scanCases } from '../components/consts'

export const anylineStates= {
  scanning : 'scanning'
}

// create a view configuration
const viewConfig = {
  outerColor: '000000',
  outerAlpha: 0.5,
  cutouts: [
    {
      cutoutConfig: {
        maxWidthPercent: '90%',
        alignment: 'center',
        width: 720,
        strokeWidth: 2,
        cornerRadius: 4,
        strokeColor: 'FFFFFFFF',
        feedbackStrokeColor: '0099FF',
      },
      scanFeedback: {
        style: 'contour_point',
        strokeColor: '0099FF',
        fillColor: '300099FF',
        strokeWidth: 2,
        cornerRadius: 4,
        animation: 'none',
      },
    },
  ],
}

// inititalize anylinejs with optional presets
// presets will override some dimension configuration of your viewConfig and modules in anylinejs config
let Anyline

export const startSinglePageScan = async (cameraFeedRef, scanCase) => await scanPage(cameraFeedRef, scanCase)
export const startTwoPagesScanFactory = (setFirstPageScanned) => async (cameraFeedRef) => {
  const firstPageResults = await scanPage(cameraFeedRef)
  setFirstPageScanned(true)

  const backPageResults = await scanPage(cameraFeedRef, 'id')

  return { ...backPageResults, result: backPageResults.result.concat(firstPageResults.result) }
}

export const stopScanning = () => Anyline.stopScanning()
export const releaseResources = () => Anyline?.dispose()
export const getState = () => Anyline.getState()

const getErrorMessage = ({ code, message }) => `${code}: ${message}`

const asyncOnResult = () =>
  new Promise((resolve, reject) => {
    Anyline.onResult = (results) => {
      logger.info('anylinejs results', results)
      resolve(results)
    }
    Anyline.onError = (error) => reject(getErrorMessage(error))
  })

const scanPage = async (element, scanCase, preset = 'id') => {
  try{
    Anyline = init({
      preset,
      viewConfig,
      license: process.env.REACT_APP_ANYLINE_LICENSE,
      element,
      debugAnyline: process.env.REACT_APP_DEBUG_ANYLINE === 'true',
      anylinePath: 'https://js.anyline.com/release/51.3.1/anylinejs'
    })
  }catch(error){
    logger.error(`Failed on anyline initialization with error: ${getErrorMessage(error)}`)
  }

  Anyline.onError = (error) => {
    logger.error(`Anyline onError triggered with error: ${getErrorMessage(error)}`)
  }
  await Anyline.startScanning()

  try {
    const onResult = await asyncOnResult()
    await countScan({ scanCase })
    return onResult
  } catch (err) {
    await countScan({ scanCase: scanCases.ScanError, error: err })
    logger.error(`Failed on single page scanning with error: ${err}`)
    throw new Error(err)
  }
}
