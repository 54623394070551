import React from 'react'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import OasisButton from './OasisButton'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { Card } from '@mui/material'
import { Link } from 'react-router-dom'
import StatusIcon from './playerstatus/StatusIcon'
import { OASIS_STATUS } from './consts'
import { getCurrentStatus, getStatusColor } from './helpers'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  banDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '30px',
    padding: '25px',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    gap: '20px',
    color: theme.palette.primary.main
  },
  banDetailsRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    textAlign: 'left'
  }
}))

const Status = ({ message, statusCode, banGiver, banStartDate }) => {
  const classes = useStyles()
  const { t } = useTranslation('oasisApp')

  const status = getCurrentStatus(statusCode)
  const isBanStartKnown = banStartDate !== ''
  const isBanGiverKnown = Object.keys(banGiver).length > 0
  const isExtraDataDisplayed = status === OASIS_STATUS.BLOCKED && (isBanStartKnown || isBanGiverKnown)

  return <Grid
    container
    className='user-status'
    justifyContent='center'
  >
    <Grid
      item
      xs={10}
    >
      <Card className='user-status__card'>
        <CardContent>
          <Grid
            container
            sx={theme => ({
              color: getStatusColor(theme.palette.primary.main)({ status })
            })}
            direction='column'
            justifyContent='center'
            alignItems='center'
          >
            <Grid
              item
              xs={12}
            >
              <StatusIcon status={status}/>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Typography
                align='center'
                variant='h5'
              >
                {message}
              </Typography>
            </Grid>
            {isExtraDataDisplayed &&
              <Grid
                item
                xs={12}
              >
                <div className={classes.banDetailsContainer}>
                  {isBanStartKnown && (
                    <div>
                      <Typography variant='h6'>{t(`playerStatus.banDetailsHeader`)}</Typography>
                      <div className={classes.banDetailsRow}>
                        <Typography>
                          {t(`playerStatus.startDate`)}:
                        </Typography>
                        <Typography>
                          {banStartDate}
                        </Typography>
                      </div>
                    </div>
                  )}
                  {isBanGiverKnown && (
                    <div>
                      <Typography variant='h6'>{t(`playerStatus.banGiverHeader`)}</Typography>
                      {Object.keys(banGiver).map((key) =>
                        <div className={classes.banDetailsRow} key={key}>
                          <Typography>
                            {t(`playerStatus.${key}`)}:
                          </Typography>
                          <Typography>
                            {banGiver[key]}
                          </Typography>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Grid>}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
    <Grid
      item
      container
      xs={10}
      className='user-status__button'
    >
      <FormControl fullWidth>
        <Link to='/app'>
          <OasisButton>
            {t('common.ok')}
          </OasisButton>
        </Link>
      </FormControl>
    </Grid>
  </Grid>
}

Status.propTypes = {
  message: PropTypes.string,
  status: PropTypes.oneOf(Object.values(OASIS_STATUS)),
  banGiver: {
    name: PropTypes.string,
    shopContactPerson: PropTypes.string,
    email: PropTypes.string
  },
  banStartDate: PropTypes.string
}
Status.defaultProps = {
  message: 'Der Spieler ist nicht gesperrt.',
  status: OASIS_STATUS.INFO,
  banGiver: {},
  banStartDate: ''
}

export default Status
