import { createContext, useContext, useState } from 'react'
import { throttle } from 'lodash'
import {
  getState,
  releaseResources,
  startSinglePageScan,
  startTwoPagesScanFactory,
  stopScanning
} from '../../libs/anyLineOCR'

const ocrContext = createContext()
export const useOCRContext = () => useContext(ocrContext)

const ProviderOCR = ({ children }) => {
  const [message, setMessage] = useState('Richten Sie die Kamera auf die Vorderseite eines Dokuments.')
  const [firstPageScanned, setFirstPageScanned] = useState(false)

  const throttledSetMessage = throttle(setMessage, 1000)
  const startTwoPagesScan = startTwoPagesScanFactory(throttledSetMessage, setFirstPageScanned)

  return <ocrContext.Provider
    value={{
      startSinglePageScan,
      startTwoPagesScan,
      message,
      firstPageScanned,
      releaseResources,
      stopScanning,
      getState
    }}>
    {children}
  </ocrContext.Provider>
}

export default ProviderOCR
