import Grid from '@material-ui/core/Grid'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const getClassName = (disabled) => classNames(
  'menu-button',
  {
    'menu-button--disabled': disabled
  }
)

const MenuButton = ({ disabled, image, label, to }) => {
  const { t } = useTranslation('oasisApp')

  return <Grid
    component={Link}
    className={getClassName(disabled)}
    item
    container
    direction='row'
    justifyContent='center'
    alignItems='center'
    to={to}
    onClick={disabled ? (event) => event.preventDefault() : null}
  >
    <Grid
      container
      item
      justifyContent='center'
      alignItems='center'
      xs={3}
    >
      {image}
    </Grid>
    <Grid
      container
      item
      xs={8}
      justifyContent='center'
      alignItems='center'
    >
      <div className='menu-button__text'>
        {t(label)}
      </div>
    </Grid>
  </Grid>
}

MenuButton.propTypes = {}
MenuButton.defaultProps = {}

export default MenuButton
