import Grid from '@material-ui/core/Grid'
import queryString from 'query-string'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation, Link } from 'react-router-dom'
import logo from '../images/svg/new-logo.png'
import { activateAccount } from '../services/api'
import OasisButton from './OasisButton'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'

const Status = {
  READY: 0,
  DONE: 1,
  FAILED: 2
}
const useStyles = makeStyles((theme) => ({
  typography: {
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  }
}))

const ActivateAccountScreen = () => {
  const { t } = useTranslation('oasisApp')
  const location = useLocation()
  const [activating, setActivating] = useState(false)
  const [activationStatus, setActivationStatus] = useState(Status.READY)
  const { token } = queryString.parse(location.search)
  const classes = useStyles()

  if (!token) {
    return <Navigate to='/' replace />
  }

  const handleOnClick = async () => {
    setActivating(true)
    try {
      const { success } = await activateAccount(token)
      setActivating(false)
      setActivationStatus(success ? Status.DONE : Status.FAILED)
    } catch {
      setActivating(false)
    }
  }

  return (
    <Grid
      className='login-screen'
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <Grid
        item
        xs={8}
        className='login-screen__logo'
      >
        <img
          src={logo}
          alt='logo'
        />
      </Grid>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
      >
        {activationStatus === Status.READY && (
          <>
            <Typography className={classes.typography}>{t('common.accountActivatedInstructions')}</Typography>
            <OasisButton
              onClick={handleOnClick}
              loading={activating}
            >
              {t('common.activateAccountButton')}
            </OasisButton>
          </>
        )}
        {activationStatus === Status.DONE && (
          <>
            <Typography className={classes.typography}>{t('common.accountActivatedSuccessfully')}</Typography>
            <Link
              component={OasisButton}
              to='/'
            >
              {t('common.loginButtonLabel')}
            </Link>
          </>
        )}
        {activationStatus === Status.FAILED && (
          <>
            <Typography className={classes.typography}>{t('common.accountActivationFailed')}</Typography>
            <Link
              component={OasisButton}
              to='/'
            >
              {t('common.loginButtonLabel')}
            </Link>
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default ActivateAccountScreen
