import merge from 'lodash.merge'
import config from './config.index'

export default merge(
  {},
  config,
  {
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  }
)
