import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { useOCRContext } from '../providers/ProviderOCR'
import { mapResults } from './helpers'
import logger from '../../logger'
import PropTypes from 'prop-types'
import { scanCases } from '../consts'

const StyledOcrContainer = styled.div`
  width: 100%;
  height: 300px;
  min-width: 300px;
`

const OCR = ({ onResults, twoSides, scanCase }) => {
  const { t } = useTranslation('oasisApp')
  const ocr = useOCRContext()
  const ocrContainerRef = useRef(false)

  const scanMethod = twoSides
    ? ocr.startTwoPagesScan
    : ocr.startSinglePageScan

  useEffect(() => {
    ocrContainerRef.current && scanMethod(ocrContainerRef.current, scanCase).then((results) => {
      const mappedResults = mapResults(results)
      return onResults(mappedResults)
    }).catch((error) => {
      logger.error(error.message)
      onResults({})
      ocr.releaseResources()
    })

    return () => {
      ocr.releaseResources()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>
    <Typography
      variant='h6'
      color='primary'
      align='center'
    >
      {t('ocr.guideMessage')}
    </Typography>
    <StyledOcrContainer ref={ocrContainerRef} />
  </>
}

OCR.propTypes = {
  onResults: PropTypes.func.isRequired,
  twoSides: PropTypes.bool,
  scanCase: PropTypes.oneOf(Object.values(scanCases))
}

OCR.defaultProps = {
  twoSides: false
}

export default OCR
