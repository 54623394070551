import logger from '../../logger'

const formFields = [
  'dateOfBirthObject',
  'surname',
  'givenNames',
  'nationalityCountryCode'
]

const regex=/\s*00:00:00 UTC\s*/

export const mapResults = ({ result }) => {
  try {
    const reducedOcrResults = Object.entries(result.mrzResult).reduce((acc, [key, value]) => {
      if (formFields.includes(key)) {
        acc[key] = value
        return acc
      } else return acc
    }, {})

    return {
      ...reducedOcrResults,
      dateOfBirth: reducedOcrResults?.dateOfBirthObject?.replace(regex, ' ') ?? '',
      firstName: reducedOcrResults?.givenNames ?? '',
      lastName: reducedOcrResults?.surname ?? ''
    }
  } catch (error) {
    console.log(error)
    logger.error(`Failed on OCR result mapping ${error}`)
    throw error
  }
}

