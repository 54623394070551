import React, { useReducer } from 'react'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import OasisButton from './../OasisButton'
import OasisTextField from './../OasisTextField'
import Typography from '@material-ui/core/Typography'
import { stateReducer } from '../consts'
import BlockFooter from './BlockFooter'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import OasisFormControlWithVal from './OasisFormControlWithVal'
import { plzErrors } from './helpers'

const getAddressDetails = address => {
  if (!address) return {}

  const split = address.split('\n')

  if (split.length) {
    if (split.length === 2) {
      const [plz, wohnort] = split[0]?.split(' ')
      const [street, houseNumber] = split[1]?.split(' ')

      return {
        plz,
        wohnort,
        street,
        houseNumber
      }
    } else {
      const [plz, wohnort] = split[0]?.split(' ')
      return {
        plz,
        wohnort
      }
    }
  }
  return {}
}

const IdBack = ({ rest, label, onResults }) => {
  const { t } = useTranslation('oasisApp')
  const { control, handleSubmit, formState: { errors }, formState } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  })

  const [state, dispatch] = useReducer(stateReducer, {
    ...getAddressDetails(rest?.address)
  })

  const plzError = errors.plz && plzErrors(errors, t)
  const buttonDisabled = !formState.isValid

  const onSubmit = () => {
    onResults(state)
  }

  return <form
    onSubmit={handleSubmit(onSubmit)}
    style={{ width: '100%' }}
  >
    <Grid
      container
      direction='column'
      alignItems='center'
      justifyContent='center'
      spacing={1}
    >
      <Grid
        item
        xs={10}
      >
        <Typography
          variant='h6'
          color='primary'
        >
          {label}
        </Typography>
      </Grid>
      <OasisFormControlWithVal
        name='plz'
        control={control}
        value={state.plz}
        rules={{ required: true, minLength: 5 }}
        onInput={(plz) => dispatch({ plz })}
        errors={errors.plz}
        errorsHint={plzError}
        label={t('common.postcode')}
        limit={5}
      />
      <OasisFormControlWithVal
        name='wohnort'
        control={control}
        value={state.wohnort}
        onInput={(wohnort) => dispatch({ wohnort })}
        errors={errors.wohnort}
        label={t('common.city')}
        errorsHint={t('errors.fieldRequired')}
      />
      <OasisFormControlWithVal
        name='street'
        control={control}
        value={state.street}
        onInput={(street) => dispatch({ street })}
        errors={errors.street}
        label={t('common.street')}
        errorsHint={t('errors.fieldRequired')}
      />
      <OasisFormControlWithVal
        name='houseNumber'
        control={control}
        value={state.houseNumber}
        onInput={(houseNumber) => dispatch({ houseNumber })}
        errors={errors.houseNumber}
        label={t('common.houseNumber')}
        errorsHint={t('errors.fieldRequired')}
      />
      <Grid
        item
        container
        xs={10}
      >
        <FormControl fullWidth>
          <OasisTextField
            label={t('common.additionalAddress')}
            onInput={({ target: { value: additionalAddress } }) => dispatch({ additionalAddress })}
            value={state.additionalAddress}
          />
        </FormControl>
      </Grid>
      <BlockFooter>
        <OasisButton
          className='form-button'
          type='submit'
          disabled={buttonDisabled}
        >
          {t('common.next')}
        </OasisButton>
      </BlockFooter>
    </Grid>
  </form>
}

IdBack.propTypes = {}

IdBack.defaultProps = {}

export default IdBack
