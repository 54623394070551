import { TextField } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'

// TODO: move this to theme overrides, and align closer to what Material allows to customize with exposed API
const useTextAreaStyles = makeStyles({
  root: {
    border: 'none',
    '& .MuiInputBase-root': {
      borderRadius: 0,
      border: '1px solid var(--color-blue)',
      paddingBottom: '9px',
      marginBottom: '23px',
      '& textarea': {
        color: 'var(--color-blue)',
        padding: '8px 12px 5px',
      },
      '&::before, &::after': {
        borderBottom: 'none'
      }
    },
    '& .MuiInputBase-root:hover': {
      '&::before, &::after': {
        borderBottom: '1px solid var(--color-blue)',
      }
    }
  }
})

const OasisTextField = forwardRef (({ centerLabel, label, multiline, ...inputProps }, ref) => {
  const textAreaClasses = useTextAreaStyles()

  return <>
    <InputLabel
      className={`${centerLabel ? 'center-label' : ''}`}
      shrink
    >
      {label}
    </InputLabel>
    <TextField
      ref={ref}
      className='oasis-text-field'
      multiline={multiline}
      classes={multiline ? textAreaClasses : {}}
      {...inputProps}
    />
  </>
})

OasisTextField.propTypes = {
  centerLabel: PropTypes.bool,
  multiline: PropTypes.bool,
  label: PropTypes.string
}
OasisTextField.defaultProps = {
  centerLabel: false,
  multiline: false,
  label: ''
}

export default OasisTextField
