import React from 'react'
import { useAuth } from '../hooks/useAuth'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'

export const PrivateRoute = () => {
  const auth = useAuth()
  const location = useLocation()

  return auth.user
    ? <Outlet />
    : (
      <Routes>
        <Route path='*' element={<Navigate to={'/'} state={{ from: location }} replace />} />
      </Routes>
    )
}
