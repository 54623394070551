import logger from '../logger'
import { rootURL } from './consts'
import { createDefaultHeaders } from './request'
import { invalidateSession } from "./user";

const fetchFromAPI = async (path, init = {}) => {
  init.headers = init.headers || createDefaultHeaders()

  try {
    const response = await fetch(`${rootURL}/${path}`, init)
    logger.http(`${init.method || 'GET'} ${response.url} ${response.status}`)
    if (response.status === 401) {
      invalidateSession()
    }
    return await response.json()
  } catch (error) {
    logger.error(`Failed to fetch from '${rootURL}/${path}'`)
    throw error
  }
}

export default fetchFromAPI
