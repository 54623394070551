import { v4 as uuidv4 } from 'uuid'
import { Logtail } from '@logtail/browser'

const isProduction = process.env.NODE_ENV === 'production'
const loggerToken = process.env.REACT_APP_BETTER_STACK_LOGGER_TOKEN

const logtail = loggerToken !== '' ? new Logtail(loggerToken) : console

const url = new URL(window.location.href)
const defaultContext = {
  runId: uuidv4(),
  applicationName: process.env.REACT_APP_NAME,
  applicationVersion: process.env.REACT_APP_VERSION,
  applicationMode: isProduction ? 'prod' : 'dev',
  applicationUrl: `${url.protocol}//${url.host}`
}


const logger = {
  context: { ...defaultContext },
  updateContext: function (context = {}) {
    this.context = { ...this.context, ...context }
  },
  setContext: function (context = {}) {
    this.context = { ...this.context, ...context }
  },
  error: function (message) {
    logtail.error(message, this.context)
  },
  warn: function (message) {
    logtail.warn(message, this.context)
  },
  info: function (message) {
    logtail.info(message, this.context)
  },
  debug: function (message) {
    logtail.debug(message, this.context)
  },
  http: function (message) {
    logtail.log(message, this.context)
  },
  start: function () {
    logtail.log('Logger started')
  }
}

if (loggerToken !== '') {
  const cleanup = () => {
    logtail.flush?.()
  }

  window.addEventListener('beforeunload', cleanup)
}

export default logger
