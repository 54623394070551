import IconButton from '@material-ui/core/IconButton'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MenuIcon from '@material-ui/icons/Menu'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { makeStyles } from '@material-ui/styles'
import React, { useState } from 'react'
import { useAuth } from '../hooks/useAuth'

const useStyles = makeStyles({
  paper: {
    borderRadius: 0
  }
})

const UserMenu = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const { signOut } = useAuth()

  return (
    <>
      <IconButton
        onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
        color='primary'
      >
        <MenuIcon alt='Toggle user menu' />
      </IconButton>
      <Menu
        classes={classes}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        open={!!anchorEl}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem
          onClick={signOut}
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText>
            Logout
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

export default UserMenu
