import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import InfoIcon from '@material-ui/icons/Info'

export const BLOCKED_STATUS_CODE = '0018'
export const MORE_THAN_ONE_BAN_STATUS_CODE = '0023'
export const UNBLOCKED_STATUS_CODE = '0019'
export const BLOCK_SUCCESS_CODE = '0007'
export const COMPATIBILITY_ERROR_CODE = '0015'

export const stateReducer = (state, changes) => {
  switch (changes) {
    case 'reset':
      return {}

    default:
      return ({
        ...state,
        ...changes
      })
  }
}

export const OASIS_STATUS = {
  BLOCKED: 'blocked',
  UNBLOCKED: 'unBlocked',
  INFO: 'info',
  BLOCKED_SUCCESSFULLY: 'blockedSuccessfully',
  ERROR: 'error',
}

export const icons = {
  [OASIS_STATUS.BLOCKED]: CancelIcon,
  [OASIS_STATUS.UNBLOCKED]: CheckCircleIcon,
  [OASIS_STATUS.BLOCKED_SUCCESSFULLY]: CheckCircleIcon,
  [OASIS_STATUS.ERROR]: CancelIcon,
  [OASIS_STATUS.INFO]: InfoIcon
}

export const defaultBirthDatePlayerStatus = '1985-01-01'
export const defaultBirthDatePlayerBlock = '2000-01-01'

export const shopTypes = {
  0: 'Shop',
  1: 'Gambling Hall'
}

export const reCaptchaActions = {
  login: 'APP_login'
}
export const scanCases = {
  'ScanError': 0,
  'PlayerStatusOneSide': 1,
  'BlockPlayerOneSide': 2
}

export const errors = {
  shopLocked: 'Shop is locked',
  notActivatedError: 'Account not activated'
}

export const lockType = {
  self: 'SELBST',
  thirdParty: 'FREMD'
}