import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import NativeSelect from '@material-ui/core/NativeSelect'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { resources } from '../i18next/translations'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  selectEmpty: {
    color: 'var(--color-blue)'
  }
}))

const LanguageSelector = () => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const handleOnChange = ({ target: { value } }) => {
    i18n.changeLanguage(value)
  }

  return (
    <div className={classes.container}>
      <FormControl>
        <NativeSelect
          value={i18n.language}
          onChange={handleOnChange}
          className={classes.selectEmpty}
          disableUnderline
          inputProps={{ 'aria-label': 'language' }}
        >
          {Object.keys(resources).map((lang) => <option key={lang} value={lang}>{t(lang)}</option>)}
        </NativeSelect>
      </FormControl>
    </div>
  )
}

export default LanguageSelector