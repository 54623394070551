import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import { Controller } from 'react-hook-form'
import OasisTextField from '../OasisTextField'
import { errorExists } from '../helpers'
import logger from '../../logger'

const LIMIT_CHECK_DISABLED = -1

const OasisFormControlWithVal = ({
  name,
  control,
  onInput,
  value,
  rules,
  errors,
  errorsHint,
  label,
  type,
  limit
}) => {
  const handleOnInput = ({ target: { value } }) => {
    if (limit !== LIMIT_CHECK_DISABLED && value.length > limit) {
      return
    }
    if (name === 'plz') {
      if (new RegExp('^[0-9]*$').test(value)) {
        onInput(value)
      } else {
        return
      }
    }
    onInput(value)
  }
  errorExists(errors) && logger.debug(`Field: ${name} filled with: ${value} with error: ${errors.type}`)

  return <Grid
    item
    container
    xs={10}
  >
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        defaultValue={value}
        rules={rules}
        render={({ field }) =>
          <OasisTextField
            {...field}
            onInput={handleOnInput}
            value={value}
            error={errorExists(errors)}
            helperText={errorExists(errors) && errorsHint}
            label={label}
            type={type}
          />}
      />
    </FormControl>
  </Grid>
}

OasisFormControlWithVal.defaultProps = {
  name: '',
  control: {},
  onInput: () => {},
  value: '',
  rules: { required: true },
  errors: {},
  errorsHint: 'This field is required',
  label: '',
  limit: LIMIT_CHECK_DISABLED,
  type: 'text'
}

OasisFormControlWithVal.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  onInput: PropTypes.func,
  value: PropTypes.string,
  rules: PropTypes.shape({ required: PropTypes.bool }),
  errors: PropTypes.object,
  errorsHint: PropTypes.string,
  label: PropTypes.string,
  limit: PropTypes.number,
  type: PropTypes.string
}

export default OasisFormControlWithVal
