import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { icons, OASIS_STATUS } from '../consts'

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '150px',
    marginTop: theme.spacing(4),
  }
}))

const StatusIcon = ({ status }) => {
  const Icon = icons[status]
  const classes = useStyles()

  return <Icon className={classes.icon} />
}

StatusIcon.propTypes = {
  status: PropTypes.oneOf(Object.values(OASIS_STATUS))
}
StatusIcon.defaultProps = {
  status: OASIS_STATUS.INFO
}

export default StatusIcon
