import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { PaddingFooter } from '../Common'
import OasisButton from '../OasisButton'
import OCR from './OCR'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { scanCases } from '../consts'
import { useOCRContext } from '../providers/ProviderOCR'
import { anylineStates } from '../../libs/anyLineOCR'

const useStyles = makeStyles((theme) => ({
  typography: {
    marginTop: theme.spacing(16),
    marginBottom: theme.spacing(16),
  }
}))

const OCRView = ({ onResults, href, scanCase }) => {
  const { t } = useTranslation('oasisApp')
  const classes = useStyles()
  const ocr = useOCRContext()

  return <Grid
    item
    container
    direction='column'
    justifyContent='center'
    alignItems='center'
    xs={10}
  >{
    !process.env.REACT_APP_ANYLINE_LICENSE
      ? <Typography
        variant='h6'
        color='primary'
        align='center'
        className={classes.typography}
      >
        {t('ocr.notAvailable')}
      </Typography>
      : <OCR onResults={onResults} scanCase={scanCase}/>
  }
    <PaddingFooter
      item
      container
      xs={10}
    >
      <OasisButton
        fullWidth
        className='form-button'
        onClick={() => {
          if (ocr.getState() === anylineStates.scanning) {
            ocr.stopScanning()
          }
          onResults({})
        }}
      >
        {t('common.next')}
      </OasisButton>
    </PaddingFooter>
  </Grid>
}

export default OCRView

OCRView.propTypes = {
  onResults: PropTypes.func.isRequired,
  href: PropTypes.string.isRequired,
  scanCase: PropTypes.oneOf(Object.values(scanCases))
}

