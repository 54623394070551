import { localeData, ns, resources, supportedLngs } from '../translations'

const config = {
  supportedLngs,
  resources,
  ns,
  defaultNS: 'common',
  fallbackNS: 'common',
  fallbackLng: 'en-US',
  lng: 'de-DE',
  nonExplicitSupportedLngs: true,
  debug: process.env.NODE_ENV !== 'production',
  cleanCode: true,
  i18nFormat: {
    localeData
  },
  detection: {
    // order and from where user language should be detected
    order: ['cookie'],

    // keys or params to lookup language from
    lookupCookie: 'i18next'
  }
}

export default config
