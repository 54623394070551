import React from 'react'
import { PaddingFooter } from '../Common'

const BlockFooter = ({ children }) =>
  <PaddingFooter
    item
    container
    xs={10}
    spacing={2}
  >
    {children}
  </PaddingFooter>

export default BlockFooter