import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import React, { useCallback, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../hooks/useAuth'
import OasisButton from './OasisButton'
import OasisTextField from './OasisTextField'
import { useNavigate } from 'react-router-dom'
import { errors, reCaptchaActions } from './consts'
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import logger from '../logger'

const reduceForm = (state, update) => ({
  ...state,
  ...update
})

const LoginForm = () => {
  const { t } = useTranslation('oasisApp')
  const navigate = useNavigate()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const auth = useAuth()
  const [loading, setLoading] = useState(false)
  const [{ login, password, reCaptcha }, updateForm] = useReducer(
    reduceForm,
    { login: '', password: '', reCaptcha: '' }
  )
  const [error, setError] = useState(false)
  const helperText = (error) => error ? t('common.helperText') : ''

  const handleOnLogin = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setLoading(true)
    auth.signIn(
      login,
      password,
      reCaptcha,
      (res) => {
        if (res.shops?.length === 1) {
          auth.selectShop(
            res.shops[0].id,
            () => {
              navigate('/app', { replace: true })
            },
            ({ message }) => {
              if (message === errors.shopLocked) {
                navigate('/app/shoplocked', { replace: true })
              } else {
                navigate('/app/novalidlicense', { replace: true })
              }
            }
          )
        } else {
          navigate('/app/shopselection', { replace: true })
        }
      },
      (error) => {
        if (error.message === errors.notActivatedError) {
          navigate('/notactivated', { replace: true, state: { email: login } })
        }
        setLoading(false)
        handleReCaptchaVerify()
        setError(error)
      })
  }

  const submitDisabled = !password || !login || !reCaptcha

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      logger.warn('Execute recaptcha not yet available')
      return
    }

    try {
      const token = await executeRecaptcha(reCaptchaActions.login)
      updateForm({ reCaptcha: token })
    } catch (error) {
      logger.error(`Execute recaptcha failed with error: ${error}`)
      throw error
    }

  }, [executeRecaptcha])

  return (
    <Grid
      component='form'
      action='#'
      onSubmit={handleOnLogin}
      container
      className='login-form'
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <Grid
        item
        container
        xs={10}
        className='login-form__element'
      >
        <FormControl fullWidth>
          <OasisTextField
            centerLabel
            label={t('common.emailLabel')}
            onInput={({ target: { value } }) => updateForm({ login: value })}
            value={login}
            error={!!error}
            helperText={helperText(error)}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        container
        xs={10}
        className='login-form__element'
      >
        <FormControl fullWidth>
          <OasisTextField
            centerLabel
            label={t('common.passwordLabel')}
            type='password'
            onInput={({ target: { value } }) => updateForm({ password: value })}
            value={password}
            error={!!error}
            helperText={helperText(error)}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        container
      >
        <GoogleReCaptcha action='APP_login' onVerify={handleReCaptchaVerify} />
      </Grid>
      <Grid
        item
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
        xs={10}
        className='login-form__element login-form__button'
      >
        <OasisButton
          type='submit'
          disabled={submitDisabled}
          loading={loading}
        >
          {t('common.loginButtonLabel')}
        </OasisButton>
      </Grid>
    </Grid>
  )
}

LoginForm.propTypes = {
  onLogin: PropTypes.func
}

LoginForm.defaultProps = {
  onLogin: () => {}
}

export default LoginForm
