import fetchFromAPI from './fetchFromAPI'
import logger from "../logger";

export const userSignIn = (email, password, reCaptcha) => fetchFromAPI(
  'app/login',
  {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({ email, password, reCaptcha })
  }
)

export const userSignOut = () => fetchFromAPI('app/signOut', { method: 'POST', })

export const invalidateSession = () => {
  logger.info('Logged out with 401')
  localStorage.removeItem('bearer_token')
  window.location.reload()
}
