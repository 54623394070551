import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import OasisButton from './OasisButton'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import Logo from './Logo'

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    marginBottom: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  typography: {
    textAlign: 'center',
  }
}))

const LockedShop = () => {
  const { t } = useTranslation('oasisApp')
  const classes = useStyles()
  const navigate = useNavigate()
  const { signOut } = useAuth()
  const auth = useAuth()

  const showBackToSelectionBtn = auth.user?.shops?.length !== 1
  return (
    <Grid
      className='login-screen'
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <Logo />
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
        className={classes.titleContainer}
      >
        <Typography className={classes.typography}>{t('common.lockedShop')}</Typography>
      </Grid>
      <Grid item>
        {showBackToSelectionBtn &&
        <OasisButton onClick={() => navigate(-1)}>
          {t('common.backToShopSelection')}
        </OasisButton>}
        <OasisButton
          className={classes.button}
          onClick={signOut}
        >
          {t('common.logout')}
        </OasisButton>
      </Grid>
    </Grid>)
}

export default LockedShop
