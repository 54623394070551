import React from 'react'
import Grid from '@material-ui/core/Grid'
import logo from '../images/svg/new-logo.png'

const Logo = () =>
  <Grid
    item
    xs={8}
    className='login-screen__logo'
  >
    <img src={logo} alt='logo' />
  </Grid>

export default Logo
