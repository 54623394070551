import React from 'react'
import PropTypes from 'prop-types'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import InputLabel from '@material-ui/core/InputLabel'
import OasisTextField from './OasisTextField'

const OasisDatePicker = ({ label, ...datePickerProps }) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <InputLabel shrink>{label}</InputLabel>
    <MobileDatePicker
      {...datePickerProps}
      sx={theme => ({
        backgroundColor: theme.palette.primary.main,
        color: '#ffffff',
        span: {
          display: 'none'
        }
      })}
      openTo='year'
      format='yyyy-MM-dd'
      inputFormat='yyyy-MM-dd'
      views={['year', 'month', 'day']}
      renderInput={(props) => <OasisTextField {...props} />}
      className='oasis-text-field'
    />
  </LocalizationProvider>
)

OasisDatePicker.propTypes = {
  label: PropTypes.string
}
OasisDatePicker.defaultProps = {
  label: ''
}
export default OasisDatePicker
