import React from 'react'
import Container from '@material-ui/core/Container'
import { useTranslation } from 'react-i18next'
import { createTheme as createThemeV4 } from '@material-ui/core/styles'
import { createTheme as createThemeV5 } from '@mui/material/styles'
import { ThemeProvider as ThemeProviderV4 } from '@material-ui/styles'
import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles'
import LanguageSelector from './LanguageSelector'
import Typography from '@material-ui/core/Typography'

const options = {
  palette: {
    primary: {
      main: '#4d6bb2'
    },
    text: {
      primary: 'rgba(0,0,0,0.87)'
    }
  },
  overrides: {
    MuiInputLabel: {
      root: {
        fontSize: 18,
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: 0,
        boxShadow: 'none',
        outline: 'none'
      }
    },
    MuiSelect: {
      root: {
        borderRadius: 0
      },
      outlined: {
        borderRadius: 0
      }
    },
    MuiButton: {
      root: {
        borderRadius: 0
      }
    }
  }
}

const themeV4 = createThemeV4(options)
const themeV5 = createThemeV5(options)

const Layout = ({ children }) => {
  const { t } = useTranslation('common')
  const isProduction = process.env.REACT_APP_ENVIRONMENT === 'prod'

  return (
    <ThemeProviderV4 theme={themeV4}>
      <ThemeProviderV5 theme={themeV5}>
        <Container maxWidth='sm'>
        {children}
        <LanguageSelector />
        {!isProduction &&
          <Typography variant='h5' align='center' color='error'>{t('testEnvironment')}</Typography>}
        </Container>
      </ThemeProviderV5>
    </ThemeProviderV4>
  )
}

export default Layout
