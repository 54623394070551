import React from 'react'

const PlayerStatusIcon = () =>
  <svg
    width='75'
    height='50'
    viewBox='0 0 75 50'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
  >
    <title>Spieler status</title>
    <path d='M28.3 9H9.7C9.4 9 9.1 9.1 8.8 9.3C8.5 9.5 8.5 9.8 8.5 10.1V28.8C8.5 29.1 8.6 29.4 8.8 29.6C9 29.8 9.4 30 9.7 30H28.4C28.7 30 29 29.9 29.2 29.7C29.4 29.5 29.5 29.2 29.5 28.9V10.2C29.5 9.9 29.4 9.6 29.2 9.4C29 9.2 28.6 9 28.3 9ZM12.1 27.7C12.4 26.1 13.2 24.6 14.5 23.5C15.8 22.4 17.4 21.8 19 21.8C20.6 21.8 22.3 22.4 23.5 23.5C24.8 24.6 25.6 26 25.9 27.7H12.1ZM16.7 17.2C16.7 16.7 16.8 16.3 17.1 15.9C17.4 15.5 17.7 15.2 18.1 15C18.5 14.8 19 14.8 19.4 14.9C19.9 15 20.3 15.2 20.6 15.5C20.9 15.8 21.1 16.2 21.2 16.7C21.3 17.2 21.2 17.6 21.1 18C20.9 18.4 20.6 18.8 20.2 19C19.8 19.3 19.4 19.4 18.9 19.4C18.3 19.4 17.7 19.2 17.3 18.7C16.9 18.4 16.7 17.8 16.7 17.2ZM27.2 24.3C26.2 22.4 24.5 21 22.6 20.2C23.2 19.5 23.6 18.7 23.7 17.8C23.8 16.9 23.7 16 23.3 15.2C22.9 14.4 22.3 13.7 21.6 13.2C20.8 12.7 20 12.5 19.1 12.5C18.2 12.5 17.3 12.8 16.6 13.2C15.8 13.7 15.2 14.4 14.9 15.2C14.5 16 14.4 16.9 14.5 17.8C14.6 18.7 15 19.5 15.6 20.2C13.6 21 12 22.5 11 24.3V11.3H27.3V24.3H27.2Z' />
    <path d='M56.5 14C46.6 14 38.5 22.1 38.5 32C38.5 41.9 46.6 50 56.5 50C66.4 50 74.5 41.9 74.5 32C74.5 22.1 66.4 14 56.5 14ZM56.5 47C48.2 47 41.5 40.3 41.5 32C41.5 23.7 48.2 17 56.5 17C64.8 17 71.5 23.7 71.5 32C71.5 40.3 64.8 47 56.5 47Z' />
    <path d='M48.2001 32.1L53.3001 37.2L65.5001 25L67.2001 26.7L54.1001 39.7C53.9001 39.9 53.6001 40 53.3001 40C53.0001 40 52.7001 39.9 52.5001 39.7L46.6001 33.8L48.2001 32.1Z' />
    <path d='M0.5 3C0.5 1.3 1.8 0 3.5 0H67.5C69.2 0 70.5 1.3 70.5 3V20.7C69.6 19.6 68.6 18.6 67.4 17.7V4.1C67.4 3.5 67 3.1 66.4 3.1H4.6C4 3.1 3.6 3.5 3.6 4.1V41C3.6 41.6 4 42 4.6 42H41.5C42.2 43.1 43.1 44.1 44.1 45.1H3.5C1.8 45.1 0.5 43.8 0.5 42.1V3Z' />
  </svg>

export default PlayerStatusIcon
