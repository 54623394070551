import React from 'react'
import { Grid } from '@material-ui/core'
import WizardStep from './WizardStep'
import PropTypes from 'prop-types'

const BlockWizard = ({ steps, stepsDone, currentId }) =>
  <Grid
    item
    container
    justifyContent='center'
    alignItems='center'
    direction='row'
    xs={12}>
    {steps.map(({ id, label }, index) =>
      <WizardStep
        key={id}
        id={id}
        number={index + 1}
        separator={steps.length - 1 === index}
        label={label}
        done={stepsDone.includes(id)}
        currentId={id === currentId}
      />
    )}
  </Grid>

BlockWizard.propTypes = {
  steps: PropTypes.array,
  stepsDone: PropTypes.array,
  currentId: PropTypes.string
}

export default BlockWizard
