import React, { createContext, useEffect, useState } from 'react';
import dictionaryMetadata from '../services/dictionaryMetadata';
import { useAuth } from "./useAuth";

const MetadataContext = createContext(null);

export const MetadataProvider = ({ children }) => {
    const [metadata, setMetadata] = useState(null);
    const { user } = useAuth()

    const fetchMetadata = async () => {
        try {
            const data = await dictionaryMetadata();
            setMetadata(data.data);
        } catch (error) {
            console.error('Failed to fetch dictionary metadata:', error);
        }
    };

    useEffect(() => {
        if (user) {
            fetchMetadata();
        }
    }, [user]);

    return (
        <MetadataContext.Provider value={metadata}>
            {children}
        </MetadataContext.Provider>
    );
};

export default MetadataContext;
