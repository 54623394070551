import { Alert, AlertTitle } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

const ScheduledLockInfo = ({ children, ...props }) => (
  <Alert
    severity='warning'
    variant='filled'
    sx={{ borderRadius: 0, width: '100%', maxWidth: '425px', marginBottom: '15px'}}
    {...props}
  >
    <AlertTitle sx={{ fontFamily: 'Roboto', fontSize: '14px', paddingTop: '3px' }}>
      <strong>{children}</strong>
    </AlertTitle>
  </Alert>
)

export default ScheduledLockInfo

ScheduledLockInfo.propTypes = {
  children: PropTypes.node
}

ScheduledLockInfo.defaultProps = {
  children: null
}
