export const validateLand = (landId) => landId !== '999' && landId !== ''

export const plzErrors = (errors, t) => {
  switch (errors?.plz.type) {
    case 'maxLength':
      return t('errors.postCodeTooLong')
    case 'minLength':
      return t('errors.postCodeTooShort')
    case 'required':
      return t('errors.fieldRequired')
    default:
      return ''
  }
}
