import { Checkbox, FormControlLabel, MenuItem } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import OasisButton from './../OasisButton'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React, { useReducer } from 'react'
import OasisSelect from '../OasisSelect'
import { stateReducer } from '../consts'
import { useTranslation } from 'react-i18next'
import OasisDatePicker from './../OasisDatePicker'
import BlockFooter from './BlockFooter'
import { addMonths } from 'date-fns'

const Reason = ({ label, blockReasons, isSelfLock, onResults }) => {
  const { t } = useTranslation('oasisApp')
  const blockTo = isSelfLock ? addMonths(new Date(), 3) : new Date()
  const [state, dispatch] = useReducer(stateReducer, {
    checked: false,
    reasonId: blockReasons.length ? blockReasons[0].kbez1 : '',
    blockTo,
    blockFrom: new Date()
  })

  return <Grid
    container
    direction='column'
    alignItems='center'
    justifyContent='center'
    spacing={1}
  >
    <Grid
      item
    >
      <Typography
        variant='h6'
        color='primary'
      >
        {label}
      </Typography>
    </Grid>
    <Grid
      item
      container
      xs={10}
    >
      <OasisSelect
        label={t('playerBlock.blockingReason')}
        id='reason'
        value={state.reasonId}
        onChange={({ target: { value: reasonId } }) => dispatch({ reasonId })}
      >
        {blockReasons.map(({ lbez, kbez1 }) =>
          <MenuItem
            key={kbez1}
            value={kbez1}
          >
            <em>{lbez}</em>
          </MenuItem>
        )}
      </OasisSelect>
    </Grid>
    <Grid
      item
      container
      xs={10}
    >
      <FormControl fullWidth>
        <OasisDatePicker
          label={t('playerBlock.lockDate')}
          onChange={(blockFrom) => dispatch({ blockFrom })}
          value={state.blockFrom}
          disabled
        />
      </FormControl>
    </Grid>
    <Grid
      item
      container
      xs={10}
    >
      <FormControl fullWidth>
        <OasisDatePicker
          label={t('playerBlock.endOfLockingPeriod')}
          onChange={(date) => {dispatch({ blockTo: date })}}
          value={state.blockTo}
          minDate={blockTo}
          disablePast
        />
      </FormControl>
    </Grid>
    <Grid
      item
      container
      xs={10}
    >
      <FormControlLabel
        control={
          <Checkbox
            color='primary'
            checked={state.checked}
            onChange={({ target: { checked } }) => dispatch({ checked })}
            name='checked'
          />
        }
        label={t('playerBlock.checkBoxText')}
      />
    </Grid>
    <BlockFooter>
      <OasisButton
        className='form-button'
        disabled={!state.checked}
        onClick={() => onResults(state)}
      >
        {t('common.next')}
      </OasisButton>
    </BlockFooter>
  </Grid>
}

Reason.propTypes = {}

Reason.defaultProps = {
  isSelfBlock: true,
  blockReasons: [],
  text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500.'
}

export default Reason
