import common from './common.json'
import oasisApp from './oasisApp.json'

const translationConfig = {
  resources: {
    common,
    oasisApp
  }
}

export default translationConfig
