import { MenuItem } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import React, { useState } from 'react'
import { useAuth } from '../hooks/useAuth'
import OasisSelect from './OasisSelect'
import OasisButton from './OasisButton'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Logo from './Logo'
import { errors } from './consts'

const ShopSelection = () => {
  const { t } = useTranslation('oasisApp')
  const { user: { shops = [] } = {}, selectShop } = useAuth() // Applied destructuring with default values
  const [selectedShopId, setSelectedShopId] = useState('')
  const navigate = useNavigate()

  const handleShopSelect = () => {
    selectShop(
      selectedShopId,
      () => {
        navigate('/app', { replace: true })
      },
      ({ message }) => {
        if (message === errors.shopLocked) {
          navigate('/app/shoplocked')
        } else {
          navigate('/app/novalidlicense')
        }
      }
    )
  }

  return (
    <Grid
      className='login-screen'
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <Logo />
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
      >
        <OasisSelect
          id='choose-shop'
          label={t('common.chooseShopFieldLabel')}
          value={selectedShopId}
          onChange={({ target: { value } }) => setSelectedShopId(value)}
        >
          {shops.map(({ name, id }) => // Changed auth.user.shops to Shops
            <MenuItem key={id} value={id}>
              <em>{name}</em>
            </MenuItem>
          )}
        </OasisSelect>
        <OasisButton onClick={handleShopSelect}>
          {t('common.chooseShopButtonLabel')}
        </OasisButton>
      </Grid>
    </Grid>
  )
}

export default ShopSelection
