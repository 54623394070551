import React, { useEffect, useState } from 'react'
import logger from '../../logger'
import { getConnectivityStatus } from '../../services/api'
import { ConnectivityContext } from '../../hooks/useConnectivityStatus'
import { Outlet } from 'react-router-dom'
import Header from '../Header'

// TODO: this should be checked by the API and we should return a simple bool for the App
const ONLINE_CODE = '0049'

const ConnectivityProvider = () => {
  const [online, setOnline] = useState(undefined)

  useEffect(
    () => {
      let timer
      const checkStatus = async () => {
        try {
          logger.debug('Checking connection status')
          const data = await getConnectivityStatus()
          setOnline(data.statusCode === ONLINE_CODE)
        } catch (error) {
          logger.error('Cannot check connection status')
          setOnline(false)
        }
        timer = setTimeout(() => checkStatus(), 30_000)
      }

      checkStatus()
      return () => clearTimeout(timer)
    },
    []
  )

  return (
    <ConnectivityContext.Provider value={online}>
      <Header />
      <Outlet />
    </ConnectivityContext.Provider>
  )
}

export default ConnectivityProvider
