import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import OasisButton from './../OasisButton'
import Typography from '@material-ui/core/Typography'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import BlockFooter from './BlockFooter'
import { useTranslation } from 'react-i18next'
import logger from '../../logger'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2.5),
  },
}))

const Transmission = ({ disabled, label, onTransmission }) => {
  const { t } = useTranslation('oasisApp')
  const [loading, setLoading] = React.useState(false)
  const [transmitConfirmed, setTransmitConfirmed] = useState(false)
  const classes = useStyles()

  const buttonDisabled = !transmitConfirmed || disabled || loading

  const handleButtonClick = () => {
    setLoading(true)
    onTransmission()
  }

  disabled && logger.debug('Not enough steps done')

  return <Grid
    container
    direction='column'
    alignItems='center'
    justifyContent='center'
    spacing={1}
  >
    <Grid
      item
      container
      direction='column'
      alignItems='center'
      justifyContent='center'
      xs={10}
    >
      <Typography
        align='center'
        variant='h6'
        color='primary'
      >
        {label}
      </Typography>
    </Grid>
    <Grid
      item
      container
      xs={10}
    >
      <div className={classes.container}>
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              checked={transmitConfirmed}
              onChange={({ target: { checked } }) => setTransmitConfirmed(checked)}
              name='checked'
            />
          }
          label={t('playerBlock.transmitText')}
        />
      </div>
    </Grid>
    <BlockFooter>
      <OasisButton
        className='form-button'
        onClick={handleButtonClick}
        fullWidth
        disabled={buttonDisabled}
        loading={loading}
      >
        {t('playerBlock.transmissionBtnLabel')}
      </OasisButton>
    </BlockFooter>
  </Grid>
}

Transmission.propTypes = {}

Transmission.defaultProps = {}

export default Transmission
