import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import IdFormScreen from './IdFormScreen'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import Status from '../Status'
import OCRView from '../OCR/OCRView'
import { scanCases } from '../consts'
import ProviderOCR from '../providers/ProviderOCR'

const PlayerStatus = () => {
  const [results, setResults] = useState({})
  const [playerStatus, setPlayerStatus] = useState({})

  const navigate = useNavigate()
  const location = useLocation()
  const getPath = (path) => `${location.pathname}/${path}`

  const handleOnResults = (ocrResult) => {
    setResults(ocrResult)
    navigate(getPath('idForm'))
  }

  return <div>
    <ProviderOCR>
      <Grid
        container
        item
        xs={12}
        direction='column'
        justifyContent='center'
        alignItems='center'
      >
        <Routes>
          <Route
            path='' element={
            <OCRView
              onResults={handleOnResults}
              href={getPath('idForm')}
              scanCase={scanCases.PlayerStatusOneSide}
            />
          }
          />
          <Route
            path='idForm' element={
            <IdFormScreen
              firstName={results.firstName}
              lastName={results.lastName}
              dateOfBirth={results.dateOfBirth}
              placeOfBirth={results.placeOfBirth}
              onPlayerStatus={(status) => {
                setPlayerStatus(status)
                navigate('status')
              }}
            />
          }
          />
          <Route
            path='status' element={
            <Status
              statusCode={playerStatus.statusCode}
              message={playerStatus.message}
              banGiver={playerStatus.banGiverData}
              banStartDate={playerStatus.banStartDate}
            />
          }
          />
        </Routes>
      </Grid>
    </ProviderOCR>
  </div>
}

export default PlayerStatus
