import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import PropTypes from 'prop-types'
import React from 'react'
import { Select } from '@mui/material'

const selectStyles = (error) => ({
  borderRadius: 0,
  boxSizing: 'border-box',
  height: '50px',
  border: 1,
  borderColor: !!error ? 'var(--color-red)' : 'var(--color-blue)',
  '&:hover': {
    borderBottom: 2,
    borderColor: !!error ? 'var(--color-red)' : 'var(--color-blue-dark)'
  },
  '&:focus': {
    backgroundColor: 'rgba(255, 255, 255, 0)'
  },
  '& em': {
    color: !!error ? 'var(--color-red)' : 'var(--color-blue)'
  },
  '.MuiSvgIcon-root ': {
    fill: !!error ? 'var(--color-red)' : 'var(--color-blue)'
  }
})

const OasisSelect = ({ id, error, label, children, ...inputProps }) => (
  <FormControl
    variant='outlined'
    fullWidth
    className='oasis-select'
  >
    <InputLabel id={id} shrink>{label}</InputLabel>
    <Select
      sx={selectStyles(error)}
      labelId={id}
      id={`${id}-select`}
      error={!!error}
      {...inputProps}
      label={label}
    >
      {children}
    </Select>
    <FormHelperText className='form-helper-error'>
      {error || ' '}
    </FormHelperText>
  </FormControl>
)

OasisSelect.propTypes = {
  id: PropTypes.string.isRequired,
  error: PropTypes.bool,
  label: PropTypes.string
}

OasisSelect.defaultProps = {
  error: false,
  label: ''
}

export default OasisSelect
