import React from 'react'

const RefreshIcon = () =>
  <svg
    width='49'
    height='37'
    viewBox='0 0 49 37'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <title>Refresh</title>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M39.9585 9.66669L31.1252 18.5H37.7502C37.7502 25.8096 31.8097 31.75 24.5002 31.75C22.2697 31.75 20.1497 31.1979 18.3168 30.2042L15.0927 33.4284C17.8089 35.1509 21.0331 36.1667 24.5002 36.1667C34.261 36.1667 42.1668 28.2609 42.1668 18.5H48.7918L39.9585 9.66669ZM11.2502 18.5C11.2502 11.1904 17.1906 5.25001 24.5002 5.25001C26.7306 5.25001 28.8506 5.80209 30.6835 6.79584L33.9077 3.57168C31.1914 1.84918 27.9672 0.833344 24.5002 0.833344C14.7393 0.833344 6.8335 8.73918 6.8335 18.5H0.208496L9.04183 27.3333L17.8752 18.5H11.2502Z'
      fill='#4D6EC9'
    />
  </svg>

export default RefreshIcon
