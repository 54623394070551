import { Grid } from '@material-ui/core'
import React from 'react'
import useConnectivityStatus from '../../hooks/useConnectivityStatus'
import MenuButton from './MenuButton'
import PlayerBlockIcon from '../icons/PlayerBlockIcon'
import PlayerStatusIcon from '../icons/PlayerStatusIcon'
import SystemInformationIcon from '../icons/SystemInformationIcon'
import { useAuth } from '../../hooks/useAuth'
import ScheduledLockInfo from './ScheduledLockInfo'
import { useTranslation } from 'react-i18next'

const menuItems = [
  {
    image: <PlayerStatusIcon />,
    label: 'menu.playerStatus',
    href: '/app/playerstatus'
  },
  {
    image: <PlayerBlockIcon />,
    label: 'menu.playerBlock',
    href: '/app/playerblock/ocr'
  },
  {
    alwaysEnabled: true,
    image: <SystemInformationIcon />,
    label: 'menu.systemInformation',
    href: '/app/systeminformation'
  }
]

const MenuScreen = () => {
  const oasisAvailable = useConnectivityStatus()
  const { selectedShop } = useAuth()
  const { scheduledLock } = selectedShop
  const { t } = useTranslation('oasisApp')

  const time = {
    start: scheduledLock?.startTime,
    end: scheduledLock?.endTime
  }

  return (
    <Grid
      className="menu"
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={4}
    >
      {(scheduledLock?.active && scheduledLock?.reason) && (
        <ScheduledLockInfo>{t(`scheduleReason.${scheduledLock.reason}`, time)}</ScheduledLockInfo>)}
      {menuItems.map(({ alwaysEnabled, href, ...menuProps }) => {
        const navigationAvailable = oasisAvailable && !scheduledLock?.active
        const disabled = !(navigationAvailable || !!alwaysEnabled)

        return (
          <Grid container item className="menu__item" xs={10} key={href}>
            <MenuButton
              disabled={disabled}
              to={disabled ? '#' : href}
              {...menuProps}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default MenuScreen
