export const createAuthorizationHeader = () =>
  localStorage.getItem('bearer_token')
    ? { Authorization: 'Bearer ' + localStorage.getItem('bearer_token') }
    : {}

const createJsonHeader = { 'Content-Type': 'application/json; charset=UTF-8' }

export const createDefaultHeaders = () =>
  ({
    ...createAuthorizationHeader(),
    ...createJsonHeader,
  })
