import { FormHelperText, MenuItem } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React, { useReducer, useState } from 'react'
import { useForm } from 'react-hook-form'
import { defaultBirthDatePlayerBlock, stateReducer } from '../consts'
import { validateAge } from '../helpers'
import OasisSelect from '../OasisSelect'
import OasisButton from './../OasisButton'
import BlockFooter from './BlockFooter'
import { useTranslation } from 'react-i18next'
import OasisFormControlWithVal from './OasisFormControlWithVal'
import { validateLand } from './helpers'
import OasisDatePicker from '../OasisDatePicker'
import logger from '../../logger'
import { subYears } from 'date-fns'
import PropTypes from 'prop-types'

const IdFront = ({ label, landCodes, firstName, lastName, birthName, dateOfBirth, placeOfBirth, rest, onResults }) => {
  const { t } = useTranslation('oasisApp')
  const [landCodesTouched, setLandCodesTouched] = useState(false)

  const { control, handleSubmit, formState: { errors }, formState } = useForm({
    mode: 'all',
    reValidateMode: 'onChange'
  })
  const mapToLandId = (countryCode) => {
    const found = landCodes?.find((landCode) => landCode.kbez2 === countryCode)
    return found ? found.kbez1 : ''
  }

  const [state, dispatch] = useReducer(stateReducer, {
    name: firstName,
    surname: lastName,
    birthName,
    birthDate: new Date(dateOfBirth),
    landId: rest?.classInfo?.isoAlpha2CountryCode ? mapToLandId(rest?.classInfo?.isoAlpha2CountryCode) : mapToLandId('DE'),
    birthPlace: placeOfBirth || ''
  })

  const onSubmit = () => {
    onResults(state)
  }

  const isAdult = validateAge(state.birthDate)
  const isProperLandId = validateLand(state.landId)
  const buttonDisabled = () => !(formState.isValid && isAdult && isProperLandId)

  !isAdult && logger.debug(`Field: birthDate filled with: ${state.birthDate} isNotAdult`)
  !isProperLandId && logger.debug(`Field: landId filled with: ${state.landId} notProperLandId`)

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: '100%' }}
    >
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
        spacing={1}
      >
        <Grid item>
          <Typography
            variant='h6'
            color='primary'
          >
            {label}
          </Typography>
        </Grid>
        <OasisFormControlWithVal
          name='name'
          control={control}
          value={state.name}
          onInput={(name) => dispatch({ name })}
          errors={errors.name}
          label={t('common.firstName')}
          errorsHint={t('errors.fieldRequired')}
        />
        <OasisFormControlWithVal
          name='surname'
          control={control}
          value={state.surname}
          onInput={(surname) => dispatch({ surname })}
          errors={errors.surname}
          label={t('common.lastName')}
          errorsHint={t('errors.fieldRequired')}
        />
        <OasisFormControlWithVal
          name='birthName'
          control={control}
          value={state.birthName}
          onInput={(birthName) => dispatch({ birthName })}
          errors={errors.birthName}
          label={t('common.birthName')}
          errorsHint={t('errors.fieldRequired')}
        />
        <Grid
          item
          container
          xs={10}
        >
          {/*TODO Add custom validation to date input*/}
          <FormControl fullWidth>
            <OasisDatePicker
              label={t('common.birthday')}
              onChange={(birthDate) => dispatch({ birthDate })}
              value={state.birthDate}
              error={!isAdult}
              helperText={!isAdult && t('errors.playerTooYoung')}
              maxDate={subYears(new Date(), 18)}
            />
          </FormControl>
        </Grid>
        <OasisFormControlWithVal
          name='birthPlace'
          control={control}
          value={state.birthPlace}
          onInput={(birthPlace) => dispatch({ birthPlace })}
          errors={errors.birthPlace}
          label={t('common.placeOfBirth')}
          errorsHint={t('errors.fieldRequired')}
        />
        <Grid
          item
          container
          xs={10}
        >
          {/*TODO Add custom validation to select*/}
          <FormControl
            className='oasis-select'
            fullWidth
            variant='outlined'
          >
            <OasisSelect
              onChange={({ target: { value: landId } }) => dispatch({ landId })}
              onClick={() => setLandCodesTouched(true)}
              value={state.landId}
              id='country'
              label={t('common.country')}
              name='country'
              error={landCodesTouched && !isProperLandId}
            >
              {landCodes?.map(({ lbez, kbez1 }) =>
                <MenuItem
                  key={kbez1}
                  value={kbez1}
                >
                  <em>{lbez}</em>
                </MenuItem>
              )}
            </OasisSelect>
            {landCodesTouched && !isProperLandId ?
              <FormHelperText className={'form-helper-error'}>
                {t('errors.fieldRequired')}
              </FormHelperText> : ''}
          </FormControl>
        </Grid>
        <BlockFooter>
          <OasisButton
            fullWidth
            disabled={buttonDisabled()}
            type='submit'
          >
            {t('common.next')}
          </OasisButton>
        </BlockFooter>
      </Grid>
    </form>
  )
}

IdFront.defaultProps = {
  dateOfBirth: defaultBirthDatePlayerBlock,
  landCodes: []
}
IdFront.propTypes = {
  dateOfBirth: PropTypes.string
}

export default IdFront
