import { useRef, useState } from 'react'
import logger from '../logger'
import { userSignIn, userSignOut } from '../services/user'
import { userSelectShop } from '../services/shop'
import jwtDecode from 'jwt-decode'
import { noop } from 'lodash'

export const useProvideAuth = () => {
  const [user, setUser] = useState(null)
  const [selectedShop, setSelectedShop] = useState(null)
  const userRef = useRef(user)

  const signOut = () => {
    logger.info('Signing out')
    logger.setContext({})
    userSignOut().then(
      () => logger.info(`Signed out user with id: ${user.id}`),
      error => logger.error(`Signed out with error: ${error}`)
    )
    localStorage.removeItem('bearer_token')
    setUser(null)
  }

  const handleApiResponse = ({ data, token, error, success }) => {
    if (!success) {
      throw new Error(error)
    }

    try {
      const { id: employeeId, shopId } = jwtDecode(token)
      logger.setContext({ employeeId, shopId })
    } catch {
      logger.error('Failed to decode token')
      return signOut()
    }

    localStorage.setItem('bearer_token', token)

    return { data, token }
  }

  const handleSignIn = ({ data }) => {
    userRef.current = data
    setUser(userRef.current)
    return userRef.current
  }

  const handleSelectShop = ({ data }) => {
    setSelectedShop(data)
  }

  const signIn = (login, password, reCaptcha, successCallback = noop, errorCallback = noop) => {
    logger.info('Signing in')
    userSignIn(login, password, reCaptcha)
      .then(handleApiResponse)
      .then(handleSignIn)
      .then(successCallback)
      .catch((error) => {
        signOut()
        errorCallback(error)
      })
  }

  const selectShop = (shopId, successCallback = noop, errorCallback = noop) => {
    logger.info(`Setting active shop to '${shopId}'`)
    userSelectShop(shopId)
      .then(handleApiResponse)
      .then(handleSelectShop)
      .then(successCallback)
      .catch(errorCallback)
  }

  return {
    user,
    selectShop,
    signIn,
    signOut,
    selectedShop
  }
}
