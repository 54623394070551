import { Checkbox, FormControlLabel, IconButton } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useImperativeHandle, useReducer, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SignaturePad from 'signature_pad'
import styled from 'styled-components'
import { stateReducer } from '../consts'
import RefreshIcon from '../icons/RefreshIcon'
import OasisButton from './../OasisButton'
import BlockFooter from './BlockFooter'

const StyledCanvas = styled.canvas`
  border: 1px solid #4D6EC9;
  min-height: 150px;
  width: 100%
`

const StyledContainerRefresh = styled.div`
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`

const Signature = React.forwardRef(({ setSignatureFilled }, ref) => {
  const canvasRef = useRef()
  const signaturePad = useRef(null)
  useImperativeHandle(ref, () => signaturePad.current)

  useEffect(() => {
    if (!signaturePad.current) {
      const ratio = Math.max(window.devicePixelRatio || 1, 1)
      canvasRef.current.width = canvasRef.current.offsetWidth * ratio
      canvasRef.current.height = canvasRef.current.offsetHeight * ratio
      canvasRef.current.getContext('2d').scale(ratio, ratio)

      signaturePad.current = new SignaturePad(canvasRef.current, {
        backgroundColor: 'rgb(255,255,255)'
      })
      signaturePad.current.addEventListener('endStroke', () => setSignatureFilled(true))
    }
  }, [signaturePad, setSignatureFilled])

  const handleIconButtonClick = () => {
    setSignatureFilled(false)
    signaturePad.current.clear()
  }

  return <>
    <StyledCanvas ref={canvasRef} />
    <StyledContainerRefresh>
      <IconButton onClick={() => handleIconButtonClick()}>
        <RefreshIcon />
      </IconButton>
    </StyledContainerRefresh>
  </>
})

const FinalAcceptance = ({ label, onResults }) => {
  const { t } = useTranslation('oasisApp')
  const [state, dispatch] = useReducer(stateReducer, {
    dataProtection: false
  })
  const [signatureFilled, setSignatureFilled] = useState(false)

  const signatureRef = useRef(null)

  const buttonDisabled = !(state.dataProtection && signatureFilled)

  return <Grid
    container
    direction='column'
    alignItems='center'
    justifyContent='center'
    spacing={1}
  >
    <Grid
      item
    >
      <Typography
        variant='h6'
        color='primary'
      >
        {label}
      </Typography>
    </Grid>
    <Grid
      item
      container
      xs={12}
    >
      <Signature ref={signatureRef} setSignatureFilled={setSignatureFilled} />
    </Grid>
    <Grid
      item
      container
      xs={10}
    >
      <FormControlLabel
        control={
          <Checkbox
            color='primary'
            checked={state.dataProtection}
            onChange={({ target: { checked } }) => dispatch({ dataProtection: checked })}
            name='checked'
          />
        }
        label={t('playerBlock.dataProtectionText')}
      />
    </Grid>
    <BlockFooter>
      <OasisButton
        onClick={() => onResults({ ...state, signatureImage: signatureRef.current.toDataURL() })}
        fullWidth
        disabled={buttonDisabled}
        className='form-button'
      >
        {t('playerBlock.finalAcceptanceBtnLabel')}
      </OasisButton>
    </BlockFooter>
  </Grid>
}

export default FinalAcceptance
